import store from "@/state/store";

export default [
  {
    path: "/unauthorized",
    name: "Unauthorized",
    meta: {
      title: "Non Autorisé",
      authRequired: false,
    },
    component: () => import("../views/auth/500.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },

    },
  },
  {
    path: "/",
    name: "home",
    meta: {
      title: "Tableau de bord",
      authRequired: true,
      key: "dashboard"
    },
    component: () => import("../views/dashboard/index.vue"),
  },
  {
    path: "/reservations/tickets",
    name: "viewQrReservations",
    meta: {
      title: "QR code",
      authRequired: true,
      key: "list-compagnie"
    },
    component: () => import("../views/viewQr.vue"),
  },
  {
    path: "/configurations",
    name: "configurations",
    meta: {
      title: "Configurations",
      authRequired: true,
      key: "configurations"
    },
    children:[
      {
        path: "compagnies",
        name: "compagnies",
        meta: {
          title: "Configurations",
          authRequired: true,
          key: "compagnies"
        },
        children:[
          {
            path: "",
            name: "listeCompagnies",
            meta: {
              title: "Configurations",
              authRequired: true,
              key: "list-compagnie"
            },
            component: () => import("../views/configurations/compagnies/index.vue"),
          },
          {
            path: "create",
            name: "createCompagnie",
            meta: {
              title: "Configurations",
              authRequired: true,
              key: "create-compagnie"
            },
            component: () => import("../views/configurations/compagnies/create.vue"),
          },
          {
            path: ":id/edit",
            name: "editCompagnie",
            meta: {
              title: "Configurations",
              authRequired: true,
              key: "edit-ville"
            },
            component: () => import("../views/configurations/compagnies/edit.vue"),
          },
          {
            path: ":id/voyages",
            name: "compagnieVoyages",
            meta: {
              title: "Configurations",
              authRequired: true,
              key: "edit-ville"
            },
            children:[
              {
                path: "",
                name: "listCompagnieVoyages",
                meta: {
                  title: "Configurations",
                  authRequired: true,
                  key: "edit-ville"
                },
                component: () => import("../views/configurations/compagnies/voyages/liste.vue"),
              },
              {
                path: "create",
                name: "createCompagnieVoyages",
                meta: {
                  title: "Configurations",
                  authRequired: true,
                  key: "edit-ville"
                },
                component: () => import("../views/configurations/compagnies/voyages/create.vue"),
              },
              {
                path: ":voyage/edit",
                name: "editCompagnieVoyages",
                meta: {
                  title: "Configurations",
                  authRequired: true,
                  key: "edit-ville"
                },
                component: () => import("../views/configurations/compagnies/voyages/edit.vue"),
              },

            ]
          },
        ]
      },
      {
        path: "villes",
        name: "villes",
        meta: {
          title: "Configurations",
          authRequired: true,
          key: "villes"
        },
        children:[
          {
            path: "",
            name: "listeVilles",
            meta: {
              title: "Configurations",
              authRequired: true,
              key: "list-ville"
            },
            component: () => import("../views/configurations/villes/index.vue"),
          },
          {
            path: "create",
            name: "createVille",
            meta: {
              title: "Configurations",
              authRequired: true,
              key: "create-ville"
            },
            component: () => import("../views/configurations/villes/create.vue"),
          },
          {
            path: ":id/edit",
            name: "editVille",
            meta: {
              title: "Configurations",
              authRequired: true,
              key: "edit-ville"
            },
            component: () => import("../views/configurations/villes/edit.vue"),
          },
        ]
      },
      {
        path: "trajets",
        name: "trajets",
        meta: {
          title: "Configurations",
          authRequired: true,
          key: "trajets"
        },
        children:[
          {
            path: "",
            name: "listeTrajets",
            meta: {
              title: "Configurations",
              authRequired: true,
              key: "list-trajet"
            },
            component: () => import("../views/configurations/trajet/index.vue"),
          },
          {
            path: "create",
            name: "createTrajet",
            meta: {
              title: "Configurations",
              authRequired: true,
              key: "create-trajet"
            },
            component: () => import("../views/configurations/trajet/create.vue"),
          },
          {
            path: ":id/edit",
            name: "editTrajet",
            meta: {
              title: "Configurations",
              authRequired: true,
              key: "edit-trajet"
            },
            component: () => import("../views/configurations/trajet/edit.vue"),
          },
        ]
      },
    ]
  },


];